import { render, staticRenderFns } from "./LocaleStringResourceList.vue?vue&type=template&id=e4a0a5d8&scoped=true&"
import script from "./LocaleStringResourceList.vue?vue&type=script&lang=js&"
export * from "./LocaleStringResourceList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4a0a5d8",
  null
  
)

export default component.exports