<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('admin_dashboard_localization_title') }}</dmx-title>
        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ pagination.totalListCount }} st hittade
                <span class="ml-3">
                    show: <b-link @click="setPerPage(10)" :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)" :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(100)" :style="((options.perPage == 100) ? 'font-weight: bold;' : ' ')">100</b-link>
                </span>
            </b-col>
            <b-col>
                <b-input v-model="searchField"
                         @input="searchWithText()"
                         :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <b-col align="right">
                <b-button variant="success" @click="newLocaleStringResource()">
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>

        <b-table id="lsr-list-table"
                 thead-class="dmx-thead-block"
                 :busy="loading"
                 outlined
                 striped
                 bordered
                 hover
                 :items="localeStringResourceList"
                 :fields="headers"
                 @row-clicked="editLocaleStringResource">
        </b-table>
        <div>
            <b-pagination align="center" v-model="options.currentPage"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.perPage"
                          aria-controls="lsr-list-table">
            </b-pagination>
        </div>
    </div>

</template>

<style scoped>

</style>

<script>
    import localeStringResourceService from '@/services/localeStringResourceService';
    import router from '@/router';
    export default {
        name: "lsr-list",
        props: {
        },
        data: () => ({
            languageId: Number,
            search: '',
            languageList: [],
            loading: true,
            options: {
                perPage: 10,
                currentPage: 1
            },

            bcItems: [
                {
                    text: 'localization',
                    href: '/localization',
                    active: true,
                },
            ],
            //flags
            searchFieldIsLoading: false,

            // values
            searchField: '',
            localeStringResourceList: [],

            // pagination
            pagination: {
                totalListCount: 0
            },
            filterTableHeadName: '',
            timeoutId: 0,
        }),
        computed: {
            headers() {
                return [
                    {
                        text: this.$t('common_name'),
                        sortable: true,
                        value: 'resourceName',
                        key: 'resourceName'
                    },
                    {
                        text: this.$t('common_value'),
                        sortable: true,
                        value: 'resourceValue',
                        key: 'resourceValue',
                    },
                    {
                        text: this.$t('common_language'),
                        sortable: true,
                        value: 'language',
                        key: 'language'
                    },
                    {
                        text: this.$t('common_languageId'),
                        sortable: true,
                        value: 'languageId',
                        key: 'languageId',
                    }
                ]
            }
        },
        watch: {
            options: {
                handler() {
                    this.getLocaleStringResources()
                },
                deep: true,
            },
            languageId: {
                handler() {
                    this.getLanguageName();
                }
            }
        },
        methods: {
            newLocaleStringResource() {
                router.push({ name: 'localeStringResource', params: { localeStringResourceId: 0, languageId: this.languageId, languageName: this.languageName } })
            },
            editLocaleStringResource(e) {
                router.push({ name: 'localeStringResource', params: { localeStringResourceId: parseInt(e.id), languageId: this.languageId, languageName: this.languageName } })
            },
            setPerPage(num) {
                this.options.currentPage = 1;
                this.options.perPage = num;
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getLocaleStringResources();
                }, 1000);
            },

            filterByTableHeader(tableHeaderName) {
                this.filterTableHeadName = tableHeaderName;
                this.getLocaleStringResources();
            },
            getLocaleStringResources() {
                localeStringResourceService.getLocaleStringResourceList({
                    Id: this.languageId,
                    Count: this.options.perPage,
                    LocaleStringResources: [],
                    //SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    //SortDesc: this.options.sortDesc[0],
                    Page: this.options.currentPage

                })
                    .then((data) => {
                        this.languageId = data.id;
                        this.localeStringResourceList = data.items;
                        this.searchFieldIsLoading = false;
                        this.options.currentPage = data.page;
                        this.pagination.totalListCount = data.totalListCount;

                        this.loading = false;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            onLanguageChange() {
                localStorage.setItem("selectedLanguageId", this.languageId)
                this.getLocaleStringResources();
            },
            getLanguageName() {
                this.languageList.select((ln) => {
                    if (this.languageId > 0 && ln.id == this.languageId) {
                        this.languageName = ln.name
                    }
                });
            }
        },
        mounted() {
            this.getLocaleStringResources();

            var selectedLanguageId = localStorage.getItem("selectedLanguageId");
            if (selectedLanguageId) {
                this.languageId = selectedLanguageId;
            }

            Array.prototype.select = function (closure) {
                for (var n = 0; n < this.length; n++) {
                    if (closure(this[n])) {
                        return this[n];
                    }
                }

                return null;
            };

            localeStringResourceService.getLanguageList()
                .then(data => {
                    this.languageList = data;
                });
        }
    }
</script>
